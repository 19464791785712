@import '../../variables.css';

.logo {
  align-self: center;
  height: auto;
  margin-right: 1em;
  width: 8em;

  @media (min-width: var(--breakpoint-medium)) {
    & {
      width: 10em;
    }
  }
  @media (prefers-color-scheme: dark) {
    & path {
      fill: #ffffff;
    }
  }
}

.defaultColor {
  & path {
    fill: var(--color-secondary);
  }
}
