/*
 * Roboto
 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Roboto-Regular.eot');
  src: url('fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Roboto-Regular.woff2') format('woff2'), url('fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Roboto-Bold.eot');
  src: url('fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/Roboto-Bold.woff2') format('woff2'),
    url('fonts/Roboto-Bold.woff') format('woff');
}

/*
 * Roboto Mono
 */
@font-face {
  font-family: 'Roboto Mono Bold';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/RobotoMono-Bold.eot');
  src: url('fonts/RobotoMono-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/RobotoMono-Bold.woff2') format('woff2'), url('fonts/RobotoMono-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono Italic';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/RobotoMono-Italic.eot');
  src: url('fonts/RobotoMono-Italic.eot?#iefix') format('embedded-opentype'),
    url('fonts/RobotoMono-Italic.woff2') format('woff2'), url('fonts/RobotoMono-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono Regular';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/RobotoMono-Regular.eot');
  src: url('fonts/RobotoMono-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/RobotoMono-Regular.woff2') format('woff2'), url('fonts/RobotoMono-Regular.woff') format('woff');
}

/*
 * Rubik
 */
@font-face {
  font-family: 'Rubik Black';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Rubik-Black.eot');
  src: url('fonts/Rubik-Black.eot?#iefix') format('embedded-opentype'), url('fonts/Rubik-Black.woff2') format('woff2'),
    url('fonts/Rubik-Black.woff') format('woff');
}

@font-face {
  font-family: 'Rubik BlackItalic';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Rubik-BlackItalic.eot');
  src: url('fonts/Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('fonts/Rubik-BlackItalic.woff2') format('woff2'), url('fonts/Rubik-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'Rubik Medium';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Rubik-Medium.eot');
  src: url('fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/Rubik-Medium.woff2') format('woff2'),
    url('fonts/Rubik-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Rubik Regular';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/Rubik-Regular.eot');
  src: url('fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Rubik-Regular.woff2') format('woff2'), url('fonts/Rubik-Regular.woff') format('woff');
}
