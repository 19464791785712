@import '../../variables.css';

.stroke {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@media (prefers-color-scheme: dark) {
  .first {
    fill: $brand-primary;
  }
  .second {
    fill: $brand-secondary;
  }
}
