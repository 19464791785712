@import '../../variables.css';

.mapContainer {
  width: 100%;
  height: 450px;
  max-height: 100vh;
  margin-top: auto;
  user-select: none;
}

.infoWindow {
  font-family: 'Roboto', sans-serif;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.25rem;
  @media (min-width: var(--breakpoint-small)) {
    font-size: 0.9375rem;
  }
  @media (min-width: var(--breakpoint-medium)) {
    font-size: 1rem;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--color-secondary);
    a {
      background-color: var(--color-secondary);
      color: #ffffff;
      &:hover,
      &:active,
      &:focus {
        color: #ffffff;
        background-color: darken(var(--color-secondary), 8%);
      }
    }
  }
  user-select: text;
  h3 {
    font-family: 'Roboto', sans-serif;
    line-height: 1.25;
    font-weight: 700;
    margin: 0;
    font-size: 0.875rem;
    padding-right: 0.5em;
    @media (min-width: var(--breakpoint-small)) {
      font-size: 0.9375rem;
    }
    @media (min-width: var(--breakpoint-medium)) {
      font-size: 1rem;
    }
    span {
      font-weight: 400;
    }
  }
  p {
    padding-right: 0.5em;
    margin-bottom: 0.75em;
  }
}
