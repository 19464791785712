@import '@csstools/normalize.css';
@import 'variables.css';
@import 'fonts.css';
/* @import-normalize; */

:global(body) {
  font-family: Roboto, sans-serif;
  font-size: var(--font-size-base);
  line-height: 1.25rem;
  margin: 0;
  padding: 0;
}

:global(.bold) {
  font-family: 'Roboto Bold', sans-serif;
}

.wrapper {
  box-sizing: border-box;
  /* TODO: #128 (https://github.com/vib-gmbh/vib-connect-node/issues/128) */
  /* margin: 120px auto 0; Origin value */
  margin: 175px auto 0;
  min-height: 1080px;
  min-height: calc(100vh - 100px - 375px);

  @media screen and (min-width: 992px) {
    margin: 150px auto 0;
    min-height: calc(100vh - 150px - 150px);
    width: 100%;
  }

  & h1 {
    font-family: 'Rubik Black', sans-serif;
    font-size: var(--font-size-h1-small);
    line-height: var(--font-size-h1-small);
    text-transform: uppercase;

    @media screen and (min-width: var(--breakpoint-small)) {
      font-size: var(--font-size-h1);
      line-height: var(--font-size-h1);
    }
  }

  & h2 {
    font-family: 'Rubik Medium', sans-serif;
    font-size: var(--font-size-h2-small);
    line-height: var(--font-size-h2-small);
    text-transform: uppercase;

    @media screen and (min-width: var(--breakpoint-small)) {
      font-size: var(--font-size-h2);
      line-height: var(--font-size-h2);
    }
  }

  & h3 {
    font-family: 'Rubik Medium', sans-serif;
    font-size: var(--font-size-h3-small);
    line-height: var(--font-size-h3-small);
    text-transform: uppercase;

    @media screen and (min-width: var(--breakpoint-small)) {
      font-size: var(--font-size-h3);
      line-height: var(--font-size-h3);
    }
  }
}

/* TODO: #128 (https://github.com/vib-gmbh/vib-connect-node/issues/128) */
.header {
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  justify-content: space-between;
  left: 0;
  padding: 1rem 1rem 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  text-transform: uppercase;

  & :hover {
    text-decoration: underline;
  }

  & a {
    text-decoration: none;
  }

  @media screen and (min-width: var(--breakpoint-medium)) {
    margin-right: 0;
  }

  & nav {
    @media screen and (min-width: var(--breakpoint-small)) {
      font-size: 1.2rem;
    }
  }

  & li {
    margin-right: 0.5rem;

    @media screen and (min-width: var(--breakpoint-small)) {
      margin-right: 1.5rem;
    }
  }

  & ul {
    display: flex;
    list-style: none;
    margin-top: 0.9rem;

    @media screen and (min-width: var(--breakpoint-small)) {
      margin-top: 0;
    }
  }
}

.lang {
  border: none;
  margin-right: 0.5rem;
  margin-top: 0.35rem;

  @media screen and (min-width: var(--breakpoint-small)) {
    margin-right: 1rem;
  }
}
