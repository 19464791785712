:root {
  --border-radius: 0.25em;
  --breakpoint-small: 576px;
  --breakpoint-medium: 768px;
  --breakpoint-large: 992px;
  --color-danger: #eb3547;
  --color-primary: #72f887;
  --color-secondary: #263d42;
  --color-light: #d7dadb;
  --font-size-base: 16px;
  --font-size-h1: 3.2rem;
  --font-size-h1-small: 2.4rem;
  --font-size-h2: 2.1rem;
  --font-size-h2-small: 1.8rem;
  --font-size-h3: 1.5rem;
  --font-size-h3-small: 1.2rem;
  --font-size-h4: 16px;
  --font-size-h5: 14px;
  --inner-large: 960px;
}
