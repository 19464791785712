@import '../../variables.css';

/**
 * TODO:
 *   https://github.com/vib-gmbh/vib-connect-node/issues/102
 */
.grid {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin: 0 auto;
  margin-bottom: 2em;

  @media (min-width: 576px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    max-width: 30em;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: none;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.gridWrapper {
  margin: 3rem auto 0;
  max-width: 90%;

  @media screen and (min-width: 992px) {
    max-width: 960px;
  }
}

.machineInfo {
  margin-bottom: 1em;

  & .machineName {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .machineGroup {
    margin-bottom: 0;
  }
}

.instrument {
  height: auto;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  width: 100%;

  svg {
    height: auto;
    max-width: 100%;
    user-select: none;
    width: 100%;

    & text {
      font-family: 'Roboto', sans-serif;
      font-size: 21px;
      font-weight: 700;
      line-height: 1.25;
    }
  }
}

.value {
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.unit {
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.timestamp {
  font-weight: 400;
}

.instrumentLabel {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  & h3 {
    display: block;
    hyphens: auto;
    margin: 0.5em 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

    @media screen and (max-width: 576px) {
      font-size: 12px !important;
    }
  }
}
