@import '../../variables.css';

.status {
  background-color: rgba(235, 53, 71, 0.1);
  border: 2px solid var(--color-danger);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color-danger);
  padding: 1rem 0.75rem;
  width: 100%;
}
