.footer {
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5rem 1.5rem 2rem;
  width: 100%;

  @media screen and (min-width: var(--breakpoint-small) and (max-width: var(--breakpoint-medium))) {
    justify-content: flex-end;
  }

  & > * {
    box-sizing: border-box;
    margin: auto 1rem;
    margin-bottom: 1rem;
    min-width: 164px;
  }

  & address {
    margin-top: 0;
  }

  & a {
    margin: 0;
    padding: 0;
  }

  & a,
  p {
    display: block;
    font-style: normal;
    line-height: 1.25rem;
    padding: 0.25rem 0;

    @media screen and (min-width: var(--breakpoint-small)) {
      padding: 0;
    }
  }

  & p {
    margin: 0;
  }
}
