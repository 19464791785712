@import '../../variables.css';

.button {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  border-radius: 0.25em;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  outline: none;
  padding: 0.5em 0.75em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  /*  &:hover,
  &:active,
  &:focus {
    background-color: darken(var(--color-secondary), 8%);
    border: 1px solid darken(var(--color-secondary), 8%);
    color: #ffffff;
    @media (prefers-color-scheme: dark) {
      background-color: darken(var(--color-secondary), 16%);
      border: 1px solid darken(var(--color-secondary), 16%);
    }
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(darken(var(--color-secondary), 8%), 0.25);
    @media (prefers-color-scheme: dark) {
      box-shadow: 0 0 0 3px rgba(darken(var(--color-secondary), 16%), 0.25);
    }
  }

  @media (min-width: var(--breakpoint-medium)) {
    padding: 0.25em 0.75em;
  }

  @media (prefers-color-scheme: dark) {
    background-color: darken(var(--color-secondary), 8%);
    border: 1px solid darken(var(--color-secondary), 8%);
  }*/
}

.add {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-secondary);
  &:hover,
  &:active,
  &:focus {
    background-color: darken(var(--color-primary), 8%);
    border: 1px solid darken(var(--color-primary), 8%);
    color: var(--color-secondary);
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(darken(var(--color-primary), 8%), 0.25);
  }
}

.danger {
  background-color: var(--color-danger);
  border: 1px solid var(--color-danger);
  color: #ffffff;
  &:hover,
  &:active,
  &:focus {
    background-color: darken(var(--color-danger), 8%);
    border: 1px solid darken(var(--color-danger), 8%);
    color: #ffffff;
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(darken(var(--color-danger), 8%), 0.25);
  }
}

.abort {
  background-color: transparent;
  border: 1px solid desaturate(lighten(var(--color-secondary), 32%), 16%);
  color: desaturate(lighten(var(--color-secondary), 32%), 16%);
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: 1px solid desaturate(lighten(var(--color-secondary), 26%), 16%);
    color: desaturate(lighten(var(--color-secondary), 26%), 16%);
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(desaturate(lighten(var(--color-secondary), 26%), 16%), 0.25);
  }
}

.disabled {
  background-color: desaturate(lighten(var(--color-secondary), 60%), 16%);
  border: 1px solid desaturate(lighten(var(--color-secondary), 60%), 16%);
  cursor: not-allowed;
  &:hover,
  &:active,
  &:focus {
    background-color: desaturate(lighten(var(--color-secondary), 60%), 16%);
    border: 1px solid desaturate(lighten(var(--color-secondary), 60%), 16%);
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(desaturate(lighten(var(--color-secondary), 60%), 16%), 0.25);
  }
}
