.loginlogout {
  border-radius: 2rem;
  display: block;
  line-height: 1.5;
  margin-top: 0.2rem;
  max-height: 1.35rem;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

  @media screen and (min-width: var(--breakpoint-small)) {
    margin-top: unset;
    padding: 0.75rem 2rem;
  }
}
