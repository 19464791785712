@import '../../variables.css';

/** TODO:
  *   https://github.com/vib-gmbh/vib-connect-node/issues/102
  */
.machine {
  border-top: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0;

  > * {
    margin: 0.5rem 0;
  }

  @media screen and (min-width: 576px) {
    flex-direction: row;
  }
}

.machineName {
  flex-basis: 100%;

  @media screen and (min-width: 768px) {
    flex-basis: unset;
    flex: 0 0 384px;
  }
}

.readMore {
  background-color: var(--color-secondary);
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
}

.status {
  border-radius: 50%;
  height: 1rem;
  margin-left: 1.5rem;
  width: 1rem;

  &:before {
    content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMwIDMwOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzAgMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxjaXJjbGUgY3g9IjE1IiBjeT0iMTIiIHI9IjIiLz48bGluZSBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwOyIgeDE9IjE1IiB4Mj0iMTUiIHkxPSIxMyIgeTI9IjI1Ii8+PHBhdGggZD0iTTE5LjMxNiwxNS43ICBDMjAuMzQyLDE0Ljc5LDIxLDEzLjQ4LDIxLDEycy0wLjY1OC0yLjc5LTEuNjg0LTMuNyIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiLz48cGF0aCBkPSJNMjIuOTEsMTguNzggIEMyNC44MDEsMTcuMTMsMjYsMTQuNzA3LDI2LDEycy0xLjE5OS01LjEzLTMuMDktNi43OCIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDsiLz48cGF0aCBkPSJNMTAuNjg0LDE1LjcgIEM5LjY1OCwxNC43OSw5LDEzLjQ4LDksMTJzMC42NTgtMi43OSwxLjY4NC0zLjciIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiMwMDAwMDA7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7Ii8+PHBhdGggZD0iTTcuMDksMTguNzggIEM1LjE5OSwxNy4xMyw0LDE0LjcwNyw0LDEyczEuMTk5LTUuMTMsMy4wOS02Ljc4IiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMDAwMDAwO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwOyIvPjwvc3ZnPg==);
    height: 1rem;
    margin-left: -1.5rem;
    width: auto;
  }

  @media screen and (min-width: var(--breakpoint-small)) {
    margin-left: unset;
  }
}

.timestamp {
}
