@import '../../variables.css';

.caption {
  margin: 0 auto 1rem;
}

.captiontitle {
  margin: 1rem auto 0;
}

.caption,
.captiontitle,
.inventory {
  max-width: 90%;

  @media screen and (min-width: 992px) {
    max-width: var(--inner-large);
  }
}

.inventory {
  margin: 0 auto;
}

.wrapper {
  padding-bottom: 20rem;
}

.orgname {
  margin: 3rem 0;
  & span {
    background-color: #000;
    border-radius: 0.25rem;
    color: #ffffff;
    padding: 0.5rem 0.75rem;
  }
}
